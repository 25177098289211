
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "~element-ui/lib/theme-chalk/index.css";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: #fff;
}

a:hover {
  text-decoration: none;
}

#app {
  height: 100%;
  min-height: 100%;
}

#main {
  background: #fff;
  padding: 10px;
}

.timesheet-header {
  margin-bottom: 20px;
}

.day-total {
  margin-bottom: 20px;
  .badge {
    background: #d9edf7;
    color: #31708f;
  }
}

.row.alert {
  border-radius: 0;
}

#title-bar {
  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0 0;
    font-weight: bolder;
  }

  background: #ffffff;
  margin: 0 0 20px 0;
  box-shadow: 0px 2px 1px #ccc;
  position: fixed;
  top: 59px;
  z-index: 1;
  width: 100%;
}

.text-error {
  color: $text-error;
}

// Fix ElementUI's terrible word-wrapping
.el-table .cell {
    word-break: break-word;
}
