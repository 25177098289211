// Body
$body-bg: #f5f8fa;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$text-color: #636b6f;
$text-error: #F56C6C;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Panels
$panel-default-heading-bg: #fff;
